.work-experience-container {
    background-color: rgb(10, 17, 27);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .job-header {
    font-size: 2.1vw;
    text-align: center; 
  }
  
  .job-header h2 {
    margin: 0;
    text-align: center;
  }
  
  .dates{
    font-size: 1.5vw;
    color: #888;
    text-align: center;
  }
  
  .company {
    font-size: 1.9vw;
    font-weight: bold;
    margin-top: 10px;
    text-align: center;
  }
  
  .location {
    font-size: 1.5vw;
    margin-top: 5px;
    color: #888;
    text-align: center; 
  }
  
  .description {
    font-size: 1.6vw;
    margin-top: 10px;
    text-align: center;
  }
  

.photo-gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 60%; 
    margin: 0 auto;

  }
  
  .image-container {
    width: 100%;
    padding-top: 90%;
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;
  }
  
  .image-content {
    position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  }
  
  
  .caption {
    text-align: center;
    font-size: 1.5vw;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
  }
  
  .navigation-arrows {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .arrow-button {
    background: none;
    border: none;
    font-size: 3vw;
    color:antiquewhite;
    cursor: pointer;
    padding: 0;
    margin: 0 30px; 
  }
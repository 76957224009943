
.project-container-left {
    border: 1px solid #ccc;
    padding: 20px;
    margin: 2%;
    display: flex;
    align-items: center;
    font-size: 1.2vw;
  }
  .project-container-left,
.project-container {
  outline: none;
}
  
  .info-container-left {
    flex: 1;
    margin-right: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .info-container-left h2 {
    margin: 0;
    margin-bottom: 10px;
  }
  
  .info-container-left p {
    margin: 0;
  }
  
  .video-container-left {
    flex: 1;
    width: 50%;
    display: flex;
    justify-content: flex-end; 
  }
  .video-container-left video {
    width: 40vw;
    height: auto;
    display: block;
  }
  .video-container-left iframe {
    height: 45vh; 
    width: 40vw;

    display: block;
  }
  .video-container-left img {
    width: 40vw;
    height: auto;
    display: block;
  }
  
  

.nav-link {
    display: inline-block;
    padding: 0.9vw 1.35vw; 
    background-color: #333;
    color: white;
    text-decoration: none;
    border: none;
    border-radius: 5px;
    margin: 5px;
    cursor: pointer;
    font-size: 1vw;
  }
  .nav-link:hover {
    background-color: #555;
    color: yellow;
  }
  
  .nav-list {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    margin-right: 20px; 
  }
  
  .Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #222;
    padding: 0px 20px;
    z-index: 100;
  }
  
  .header-left {
    color: white;
    font-size: 2vw;
  }
  
  .name {
    display: inline-block;
    vertical-align: middle;
  }
  
  .nav-link:active,
  .nav-link:focus,
  .nav-link:visited {
    color: white; 
    background-color: #333; 
  }
  
.page-container4 {
    display: flex;
    flex-direction: column;
    background-color: rgb(10, 17, 27);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    color: white;
    padding: 5% 20px 1%; 
    align-items: center;
    text-align: center;
  }
  .page4Title{
    font-size: 50px; /* Increased font size */
  }
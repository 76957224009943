

.footer {
    background-color: #222;
    color: #fff;
    padding: 1vw;
    text-align: center;
  }
  
  .icon-container {
    display: flex;
    justify-content: center; 
    align-items: center; 
    margin-bottom: 1vw;
  }
  
  .icon {
    font-size: 3vw;
    color: #fff;
    margin: 0 0.8vw;
    text-align: center; 
    display: flex; 
    align-items: center; 
    justify-content: center; 
  }
  .footerText{
    font-size: 1vw;
    
  }



.page-container5 {
  display: flex;
  flex-direction: column;
  background-color: rgb(29, 42, 58);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  color: white;
  padding: 5% 20px 0;
  align-items: center;
  height: 100vh;
}

.page5Title {
  font-size: 50px;
}

.content-container {
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-top: 20px;
}

.text-elementPage5 {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 2vw;
  flex: 1;
  padding: 0 7vw;
  text-align: left;
}

.photo-gallery-container {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding-right: 0px; 
}


.page-container2 {
    display: flex;
    flex-direction: column;
    background-color: rgb(10, 17, 27);
  
    color: white;
    padding: 5% 20px 0%; 
    height: 100vh;
    align-items: center;
    text-align: center;
  }
  
  .title {
    font-size: 50px; 
    margin-bottom: 20px;
    margin-top: 6%;
  }
  
  .content-container {
    display: flex;
    width: 100%;
    margin-top: 40px; 
  }
  
  .bio-container {
    width: 65%;
    margin-right: 0px;
    margin-left: 20px;
    box-sizing: border-box;
  }
  

  .image-containerPage2 {
    width: 35%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-top: 10px;
    margin-left: 10px; 
    margin-right: 20px; 
  }
  .bio-text {
    font-size: 1.4vw;
  }
  
  .profile-image {
    margin-top: 6%; 
    width: 80%; 
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; 
    border-radius: 50%; 
  }

  

  